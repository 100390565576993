import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { HeaderNavigation, HeaderNavigationItem, HeaderNavigationLogo } from './HeaderStyles';
import { LoginGrid, LoginGridItem } from './LoginStyles';
import AuthenticationService from '../services/AuthenticationService';

import IconLogo from '../components/icons/IconLogo';
import ImprintPrivacyLinkbar from '../components/ImprintPrivacyLinkbar';

const NewPassword = (props) => {
	const form = useRef();
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');

	const token = props.match.params.token;

	const onChangePassword = (e) => {
		const password = e.target.value;
		setPassword(password);
	};

	const handleLogin = (e) => {
		e.preventDefault();

		setMessage('');
		setLoading(true);

		if (password) {
			AuthenticationService.newpwd(token, password).then(
				(data) => {
					const resStatus = (data && data.status) || data.status.toString();
					const resMessage = (data && data.message) || data.message.toString();
					setLoading(false);
					if (resStatus === 'success') {
						setMessage(resMessage + ' √');
					} else {
						setMessage(resMessage);
					}
				},
				(error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) ||
						error.message ||
						error.toString();

					setLoading(false);
					setMessage(resMessage);
				}
			);
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			<header>
				<HeaderNavigation>
					<HeaderNavigationItem>
						<HeaderNavigationLogo>
							<Link to='/'>
								<IconLogo />
							</Link>
						</HeaderNavigationLogo>
					</HeaderNavigationItem>
				</HeaderNavigation>
			</header>

			<LoginGrid>
				<LoginGridItem>
					<h1>Passwort zurücksetzen</h1>
					{!token && (
						<>
							<div className='form-group'>
								<div className='alert alert-danger' role='alert'>
									Es ist ein Fehler aufgetreten!
								</div>
							</div>
						</>
					)}
					{token && (
						<>
							{!message && (
								<>
									<form onSubmit={handleLogin} ref={form}>
										<div className='form-group'>
											<p>Bitte gebe ein neues Passwort ein ...</p>
										</div>
										<div className='form-group'>
											<label htmlFor='password'>Neues Passwort</label>
											<input
												type='password'
												className='form-control'
												name='password'
												value={password}
												required='required'
												onChange={onChangePassword}
											/>
										</div>

										<div className='form-group'>
											<button className='btn position-relative' disabled={loading}>
												{loading && (
													<>
														<span className='btnSpinnerWrapper'>
															<span className='btnSpinner loader'>Loading</span>
														</span>
														<span className='white loading'>Loading</span>
													</>
												)}
												{!loading && <span className='white'>Zurücksetzen</span>}
											</button>
										</div>
									</form>
								</>
							)}
							{message && (
								<div className='form-group'>
									<div className='alert alert-danger' role='alert'>
										{message} Hier geht es <Link to='/'>zurück</Link>.
									</div>
								</div>
							)}
							<ImprintPrivacyLinkbar />
						</>
					)}
				</LoginGridItem>
			</LoginGrid>
		</>
	);
};

export default NewPassword;
