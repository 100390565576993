import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconEdit = ({ className, fill, color }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' r='12' fill={fill || '#FFF'} />
			<rect
				width='3.6'
				height='12.77'
				x='10.561'
				y='4.654'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				transform='rotate(45 12.36 11.04)'
			/>
			<polygon
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				points='6.573 14.282 5.3 18.1 9.118 16.827'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M18.1483992,7.79679977 L15.6031994,5.25159993 L16.0273993,4.82739995 C16.7336813,4.14525001 17.8563518,4.15500572 18.5506726,4.84932651 C19.2449934,5.54364731 19.2547491,6.66631784 18.5725992,7.3725998 L18.1483992,7.79679977 Z'
			/>
		</g>
	</Svg>
);

export default IconEdit;
