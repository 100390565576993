import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconTechology = ({ className, fill, color }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' r='12' fill={fill || '#FFF'} />
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M13.7143994,3.97759988 C15.0942291,5.35805218 15.0942291,7.59554729 13.7143994,8.97599958 C12.6791059,10.0109823 11.0008931,10.0109823 9.96559958,8.97599958 C8.58576988,7.59554729 8.58576988,5.35805218 9.96559958,3.97759988 L11.2151995,2.72799996 C11.5603583,2.38315286 12.1196407,2.38315286 12.4647994,2.72799996 L13.7143994,3.97759988 Z'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M8.85519965,4.33839986 C10.7831782,4.64396043 12.0986087,6.45433376 11.7935995,8.38239962 C11.5642474,9.8284753 10.2060901,10.8148586 8.75999966,10.5855995 C6.8324208,10.2796659 5.51767029,8.46924266 5.82319983,6.54159973 L6.09919982,4.79999983 C6.1349752,4.56806543 6.26190933,4.3600283 6.45179226,4.22212282 C6.64167518,4.08421734 6.87877548,4.0278687 7.11039976,4.06559988 L8.85519965,4.33839986 Z'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M5.13679987,7.48639967 C5.97188636,7.06062578 6.94192833,6.98410377 7.83344494,7.27367412 C8.72496155,7.56324448 9.46489294,8.19517689 9.89039959,9.03039958 C10.5549986,10.3349064 10.0363963,11.9311842 8.73199966,12.5959994 C6.99270437,13.4815727 4.86481923,12.7900638 3.97839994,11.0511995 L3.17839999,9.47679955 C3.07057501,9.26869281 3.05022407,9.02617017 3.12185942,8.80300427 C3.19349477,8.57983837 3.35119698,8.39447007 3.55999997,8.28799963 L5.13679987,7.48639967 Z'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M3.97839994,12.2183994 C4.86481923,10.4795351 6.99270437,9.78802611 8.73199966,10.6735995 C9.35860847,10.9928566 9.83270053,11.5479918 10.0499507,12.2168461 C10.2672008,12.8857005 10.2098072,13.6134672 9.89039959,14.2399993 C9.46489294,15.075222 8.72496155,15.7071544 7.83344494,15.9967247 C6.94192833,16.2862951 5.97188636,16.2097731 5.13679987,15.7839992 L3.56239997,14.9839992 C3.35275922,14.8783786 3.1940074,14.6933759 3.12144437,14.4701282 C3.04888133,14.2468804 3.06852232,14.0038941 3.17599999,13.7951993 L3.97839994,12.2183994 Z'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M5.82239983,16.7279991 C5.51736282,14.8002005 6.83234586,12.9899502 8.75999966,12.6839994 C10.2058053,12.4552271 11.5634352,13.4414874 11.7927995,14.8871992 C12.0978087,16.8152651 10.7823782,18.6256384 8.85439965,18.931199 L7.10959976,19.207199 C6.87797548,19.2449301 6.64087518,19.1885815 6.45099226,19.050676 C6.26110933,18.9127706 6.1341752,18.7047334 6.09839982,18.472799 L5.82239983,16.7279991 Z'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M9.96559958,19.291999 C8.58576988,17.9115467 8.58576988,15.6740516 9.96559958,14.2935993 C11.0008931,13.2586166 12.6791059,13.2586166 13.7143994,14.2935993 C15.0942291,15.6740516 15.0942291,17.9115467 13.7143994,19.291999 L12.4647994,20.5415989 C12.1196407,20.886446 11.5603583,20.886446 11.2151995,20.5415989 L9.96559958,19.291999 Z'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M14.8239993,18.931199 C12.8960957,18.6256557 11.5808976,16.8151094 11.8863995,14.8871992 C11.9963391,14.1926693 12.3777703,13.5702948 12.9467277,13.1570805 C13.5156851,12.7438662 14.2255288,12.5736851 14.9199993,12.6839994 C16.8476531,12.9899502 18.1626361,14.8002005 17.8575991,16.7279991 L17.5807991,18.472799 C17.5452047,18.7047173 17.418467,18.9128175 17.2287426,19.0508671 C17.0390182,19.1889167 16.8020195,19.245483 16.5703992,19.207999 L14.8239993,18.931199 Z'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M18.5431991,15.7839992 C16.8038832,16.6697544 14.6758297,15.9786652 13.7887994,14.2399993 C13.1246767,12.935455 13.643166,11.3395249 14.9471993,10.6743995 C16.6867278,9.78875635 18.8148572,10.4802332 19.701599,12.2191994 L20.501599,13.7935993 C20.7232315,14.2283709 20.5506316,14.7604943 20.115999,14.9823992 L18.5431991,15.7839992 Z'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M19.701599,11.0511995 C18.8154902,12.7904273 16.6872571,13.4820493 14.9479993,12.5959994 C14.321446,12.2769006 13.8473674,11.7219108 13.6301107,11.0531863 C13.412854,10.3844617 13.4702262,9.65681303 13.7895994,9.03039958 C14.215106,8.19517689 14.9550374,7.56324448 15.846554,7.27367412 C16.7380706,6.98410377 17.7081126,7.06062578 18.5431991,7.48639967 L20.117599,8.28639963 C20.3268888,8.39216495 20.4853098,8.5770878 20.5577044,8.80012941 C20.6300991,9.02317102 20.6104792,9.26588218 20.503199,9.47439955 L19.701599,11.0511995 Z'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M17.8567991,6.54239973 C18.1623067,8.46986947 16.8474642,10.2800613 14.9199993,10.5855995 C14.2256526,10.6956819 13.5160164,10.5253915 12.9472391,10.1121991 C12.3784619,9.69900672 11.9971472,9.07676767 11.8871995,8.38239962 C11.5816699,6.45475668 12.8964204,4.64433342 14.8239993,4.33839986 L16.5695992,4.06239988 C16.8012235,4.0246687 17.0383238,4.08101734 17.2282067,4.21892282 C17.4180896,4.3568283 17.5450237,4.56486543 17.5807991,4.79679983 L17.8567991,6.54239973 Z'
			/>
		</g>
	</Svg>
);

export default IconTechology;
