import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconNetwork = ({ className, fill, color }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' r='12' fill={fill || '#FFF'} />
			<circle
				cx='12.047'
				cy='12.047'
				r='8.047'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<circle
				cx='9.773'
				cy='9.073'
				r='1.574'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<circle
				cx='13.621'
				cy='16.07'
				r='1'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<circle
				cx='16.77'
				cy='10.822'
				r='1.225'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M11.3470599 8.89943936C12.9112554 8.95902396 14.4016554 9.58002397 15.5453798 10.6487393M17.0140921 12.0229894L17.2939801 12.3966399C17.2939801 12.3966399 18.34356 13.7960798 18.34356 16.9448198M15.4215294 4.73960403L15.5453798 5.05097942C16.051868 6.55135996 16.2887627 8.12955298 16.2450998 9.71251399M15.8952398 11.6983193C15.8075975 13.1433952 15.2867812 14.5285812 14.4006379 15.6734286M13.2027173 16.8384624L13.0963599 16.9462192C13.0963599 16.9462192 11.3470599 18.6955192 7.49859994 18.6955192M12.0467799 4.00139944C11.0895097 5.02526531 10.3197564 6.20950112 9.77268991 7.49999938'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M8.95541696 10.4192312L8.89803992 10.6487393C8.51679496 12.54112 8.76242879 14.5061906 9.59775991 16.2464992 10.7872839 18.6297455 13.7960798 19.7450992 13.7960798 19.7450992L14.0570754 19.8423603M8.19831993 9.07296992C8.19831993 9.07296992 5.51419406 9.26539291 4.157437 10.4535175'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M4.1959216,13.8198703 L4.34985999,13.7960798 C4.34985999,13.7960798 9.16463324,13.0557761 13.0781671,15.3844442'
			/>
		</g>
	</Svg>
);

export default IconNetwork;
