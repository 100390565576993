import React, { useState } from 'react';
import EmailDataService from '../services/EmailService';

const HideButton = (props) => {
	const { email } = props;
	const { setHiddenFlag } = props;

	const [hide, setFlag] = useState(email.hidden);

	const updateHidden = (email) => {
		const toggle = hide === '1' ? '0' : '1';
		setFlag(toggle);

		const data = {
			hidden: toggle
		};
		EmailDataService.update(email.id, data)
			.then((response) => {
				setHiddenFlag(-1);
				const toggleNegt = email.hidden === '1' ? '1' : '0';
				setHiddenFlag(toggleNegt);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<>
			<div className='mt-ios'>
				<small className='text-grey'>Verbergen</small>
				<input id='hide' type='checkbox' checked={hide !== '1'} onChange={() => updateHidden(email)} />
				<label htmlFor='hide' className='ml2' />
			</div>
		</>
	);
};

export default HideButton;
