import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderNavigation, HeaderNavigationItem, HeaderNavigationLogo } from './HeaderStyles';

import { DefaultPageGrid, DefaultPageGridItem } from './DefaultPageStyles';

import IconLogo from '../components/icons/IconLogo';
import ImprintPrivacyLinkbar from '../components/ImprintPrivacyLinkbar';

const Privacy = (props) => {
	return (
		<>
			<header>
				<HeaderNavigation>
					<HeaderNavigationItem>
						<HeaderNavigationLogo>
							<Link to='/'>
								<IconLogo />
							</Link>
						</HeaderNavigationLogo>
					</HeaderNavigationItem>
				</HeaderNavigation>
			</header>

			<DefaultPageGrid>
				<DefaultPageGridItem>
					<h1>Datenschutz</h1>
					<p>Hello Datenschutz</p>
					<p className='mt2 mb2'>
						<Link to='/list' className='btn white'>
							Zurück
						</Link>
					</p>
					<ImprintPrivacyLinkbar />
				</DefaultPageGridItem>
			</DefaultPageGrid>
		</>
	);
};

export default Privacy;
