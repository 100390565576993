import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconProfile = ({ className, fill, color }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' fill={fill || '#FFF'} r='12' />
			<g stroke={color || '#85144B'} strokeLinecap='round' strokeLinejoin='round'>
				<path d='m17.0000002 14.1304002c-.2601113-.0769823-.528906-.1207958-.8-.130401-.9162547-.0007472-1.7747004.4476054-2.2976 1.200001m-5.30240012-5.2000001c-2.65096681 0-4.8 2.1490333-4.8 4.8000001s2.14903319 4.8 4.8 4.8h5.20000002' />
				<path d='m4.60000002 12.1480001c-1.20969036-1.1863412-1.54071686-3.00613367-.82629235-4.542478.71442452-1.53634432 2.31929739-2.45591284 4.0060061-2.29538248 1.68670871.16053035 3.08930233 1.36633065 3.50108633 3.00986044m1.7192 1.09680002c-.3741889-.62189054-.4918149-1.36494286-.328-2.07200003.0460907-.19360528.1137119-.38144184.2016-.56000001-.6295291-.06197045-1.1028471-.60198116-1.0817749-1.23420202.0210721-.63222087.5292973-1.13951636 1.1615557-1.15943093.6322584-.01991458 1.1714016.4543914 1.2322193 1.08403293.1982422-.09796096.4078305-.17104816.624-.2176.8562114-.18210561 1.7483188.04669301 2.4112.61840001.0880453.07865381.1924078.13684547.3056.1704l2.6736001.35360001v1.20000001c0 1.76731123-1.4326889 3.20000005-3.2000002 3.20000005v3.3304001c1.33241.3935985 2.1726979 1.7069789 1.9716298 3.0816816s-1.3823077 2.3924085-2.7716297 2.3879332h-2.4c-1.5464001 0-2.8000001-2.3280148-2.8000001-5.2000148 0-2.3568001.8448-4.3448001 2-4.98320012z' />
				<path d='m16.2 19.6h3.2' />
				<path d='m15.6000002 7.20000005c-.110457 0-.2.08954305-.2.19999999m.4 0c0-.11045694-.0895431-.19999999-.2-.19999999m0 .4c.1104569 0 .2-.08954305.2-.2m-.4 0c0 .11045695.089543.2.2.2m1.4 4.40000005c1.046091.0553629 2.0384676.4806671 2.8000001 1.2' />
			</g>
		</g>
	</Svg>
);

export default IconProfile;
