import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { HeaderNavigation, HeaderNavigationItem, HeaderNavigationLogo } from './HeaderStyles';
import { LoginGrid, LoginGridItem } from './LoginStyles';
import AuthenticationService from '../services/AuthenticationService';

import IconLogo from '../components/icons/IconLogo';
import ImprintPrivacyLinkbar from '../components/ImprintPrivacyLinkbar';

const ForgotPassword = (props) => {
	const form = useRef();
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');

	const onChangeEmail = (e) => {
		const email = e.target.value;
		setEmail(email);
	};

	const handleLogin = (e) => {
		e.preventDefault();

		setMessage('');
		setLoading(true);

		if (email) {
			AuthenticationService.forgotpwd(email).then(
				(data) => {
					const resStatus = (data && data.status) || data.status.toString();
					const resMessage = (data && data.message) || data.message.toString();
					setLoading(false);
					if (resStatus === 'success') {
						setMessage(resMessage + ' √');
					} else {
						setMessage(resMessage);
					}
				},
				(error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) ||
						error.message ||
						error.toString();

					setLoading(false);
					setMessage(resMessage);
				}
			);
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			<header>
				<HeaderNavigation>
					<HeaderNavigationItem>
						<HeaderNavigationLogo>
							<Link to='/'>
								<IconLogo />
							</Link>
						</HeaderNavigationLogo>
					</HeaderNavigationItem>
				</HeaderNavigation>
			</header>

			<LoginGrid>
				<LoginGridItem>
					<h1>Passwort vergessen</h1>
					{!message && (
						<>
							<form onSubmit={handleLogin} ref={form}>
								<div className='form-group'>
									<p>
										Bitte gebe Deine Email an. Wir schicken Dir dann weitere Instruktionen um Dein
										Passwort zurückzusetzen. Alternativ kommst Du <Link to='/'>hier</Link> wieder
										zurück.
									</p>
								</div>
								<div className='form-group'>
									<label htmlFor='email'>Email</label>
									<input
										type='email'
										className='form-control'
										name='email'
										value={email}
										required='required'
										onChange={onChangeEmail}
									/>
								</div>

								<div className='form-group'>
									<button className='btn position-relative' disabled={loading}>
										{loading && (
											<>
												<span className='btnSpinnerWrapper'>
													<span className='btnSpinner loader'>Loading</span>
												</span>
												<span className='white loading'>Loading</span>
											</>
										)}
										{!loading && <span className='white'>Anfragen</span>}
									</button>
								</div>
							</form>
						</>
					)}
					{message && (
						<>
							<div className='form-group'>
								<div className='alert alert-danger' role='alert'>
									{message} Hier geht es <Link to='/'>zurück</Link>.
								</div>
							</div>
						</>
					)}
					<ImprintPrivacyLinkbar />
				</LoginGridItem>
			</LoginGrid>
		</>
	);
};

export default ForgotPassword;
