import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 40px;
	height: auto;
	cursor: pointer;
`;

export const IconLogo = ({ className }) => (
	<Svg viewBox='0 0 100 100' className={className}>
		<g fill='none' fillRule='evenodd'>
			<rect width='100' height='100' fill='#FFF' />
			<g transform='translate(7 15)'>
				<rect width='76.68' height='60.3' x='6.12' y='3.6' fill='#DDD' rx='6.84' />
				<path
					stroke='#000'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='5.76'
					d='M43.559999,12.0599997 L7.19999984,12.0599997 C3.22354973,12.0599997 0,15.2835495 0,19.2599996 L0,62.4599986 C0,66.4364487 3.22354973,69.6599984 7.19999984,69.6599984 L67.4513985,69.6599984 C71.4278486,69.6599984 74.6513983,66.4364487 74.6513983,62.4599986 L74.6513983,41.9399991'
				/>
				<polyline
					stroke='#000'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='5.76'
					points='11.52 23.58 37.44 43.74 49.14 35.339'
				/>
				<circle cx='68.67' cy='18.27' r='18.27' fill='#85144B' />
				<path
					fill='#FFF'
					fillRule='nonzero'
					d='M77.7599983,28.8170039 C77.7599983,26.75026 77.7599983,25.6098066 77.7599983,25.395644 C77.7599983,25.0482563 77.5953352,24.8142852 77.2220637,24.8142852 C76.9732161,24.8142852 73.1832603,24.8142852 65.8521965,24.8142852 C65.8521965,12.1133619 65.8521965,5.64404284 65.8521965,5.406328 C65.8521965,5.04975575 65.6773228,4.85999989 65.3419787,4.85999989 C62.6963773,4.85999989 61.2580025,4.85999989 61.0268541,4.85999989 C60.6584606,4.85999989 60.4799986,5.05603993 60.4799986,5.40456617 C60.4799986,20.2600004 60.4799986,27.9028531 60.4799986,28.3331244 C60.4799986,28.9863714 60.8738365,29.3399993 61.3585533,29.3399993 C61.6765055,29.3399993 66.9744791,29.3399993 77.2524741,29.3399993 C77.5615852,29.3399993 77.7599983,29.155429 77.7599983,28.8170039 Z'
				/>
			</g>
		</g>
	</Svg>
);

export default IconLogo;
