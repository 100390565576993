import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthorizationHeader from './AuthorizationHeader';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const isAuth = AuthorizationHeader();
	return (
		<Route
			{...rest}
			render={(props) =>
				isAuth.Authorization ? (
					<Component {...props} {...rest} />
				) : (
					<Redirect
						to={{
							pathname: '/',
							state: {
								from: props.location
							}
						}}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
