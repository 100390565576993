import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from './services/PrivateRoute';

import Login from './pages/Login';
import List from './pages/List';
import Profile from './pages/Profile';
import Privacy from './pages/Privacy';
import Imprint from './pages/Imprint';
import ForgotPassword from './pages/ForgotPassword';
import NewPassword from './pages/NewPassword';
import FourZeroFour from './pages/FourZeroFour';

const App = () => {
	return (
		<Router>
			<Switch>
				<Route exact path={['/']} component={Login} />
				<PrivateRoute exact path={['/list']} component={List} />
				<PrivateRoute exact path={['/profile']} component={Profile} />
				<Route exact path={['/forgotpwd']} component={ForgotPassword} />
				<Route exact path={['/newpwd/:token?']} component={NewPassword} />
				<Route exact path={['/privacy']} component={Privacy} />
				<Route exact path={['/imprint']} component={Imprint} />
				<Route component={FourZeroFour} />
			</Switch>
		</Router>
	);
};

export default App;
