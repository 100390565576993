import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconProfileFemale = ({ className, fill, color }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' fill={fill || '#FFF'} r='12' />
			<g
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.202'
				transform='translate(5 4)'
			>
				<path d='m9.34602084 11.6436583c1.56113596.1576616 3.13285216-.1709509 4.50005166-.9408652.1543466-.0868183.2625305-.237307.2956552-.4112697.0331248-.1739627-.0121841-.35367839-.1238241-.49114389-.5202997-.61042089-.8819861-1.41550357-1.0405994-3.7646627-.3028072-4.46520479-3.31405676-6.03571681-5.90113585-6.03571681s-5.59832861 1.57051202-5.90113582 6.03571681c-.1586133 2.34915913-.5202997 3.15424181-1.04059939 3.7646627-.11164009.1374655-.15694889.31718119-.12382417.49114389.03312473.1739627.14130867.3244514.29565524.4112697 1.3674226.7693206 2.93894918 1.0978935 4.50005165.9408652' />
				<path d='m13.4964023 14.6789402c-1.0249784-.4229688-2.2374089-.8567522-3.55558158-1.3446082-.35882994-.1302582-.59857129-.4702114-.60080796-.8519457v-1.65823c-.00030773-.2557563.10749429-.4997293.29679913-.6717033.68391671-.6243996 1.07084591-1.50962027 1.06463171-2.43567551v-1.32177752c.0004801-.52277544-.3410588-.98434084-.84113114-1.13672867-.8938963-.27821739-1.70837557-.7659986-2.37559469-1.42271326-.2304418-.21371772-.58665704-.21371772-.81709883 0-.67068423.65687994-1.48899708 1.14350001-2.38640924 1.41910841-.50007234.15238783-.84161121.61395323-.84113115 1.13672867v1.32177752c-.00636606.92608643.38058897 1.81136617 1.06463172 2.43567546.18930484.171974.29710686.415947.29679913.6717033v1.65823c-.00223667.3817343-.24197802.7216876-.60080796.8519457-1.32177753.4878561-2.53060315.9216394-3.55558153 1.3446082' />
			</g>
		</g>
	</Svg>
);

export default IconProfileFemale;
