import styled from 'styled-components';

//
// Grid for List
// --------------------------------------------------

export const Grid = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	margin: 0;
	padding: 1rem;
`;

export const GridItem = styled.div`
	display: flex;
	width: 100%;

	@media (min-width: 767px) {
		width: 50%;
	}

	@media (min-width: 1023px) {
		width: 33.333333333%;
	}

	@media (min-width: 1379px) {
		width: 25%;
	}
`;

//
// Grid for Form
// --------------------------------------------------

export const GridForm = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: center;
	margin: 0;
	padding: 1rem;
`;

export const GridItemForm = styled.div`
	display: flex;
	width: 100%;

	@media (min-width: 767px) {
		width: 50%;
	}
`;

export const CardForm = styled.div`
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	text-decoration: none;
	background-color: #fff;
	user-select: none;
	width: 100%;

	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);

	transition: all 0.3s;
	border-radius: 0.5rem;
	padding: 3rem;
	margin: 1rem;
`;

//
// Button
// --------------------------------------------------

export const EditButton = styled.button`
	border: 0;
	background-color: transparent;
	cursor: pointer;
	transition: opacity 0.3s;
	opacity: 0;
	margin-top: 10px;
`;

//
// Card
// --------------------------------------------------

export const Card = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-content: stretch;
	text-decoration: none;
	background-color: #fff;
	user-select: none;
	width: 100%;
	border-top: 5px solid #ccc;

	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);

	transition: all 0.3s;
	border-radius: 0.5rem;
	padding: 0;
	margin: 1rem;

	&:hover {
		// transform: translateY(-0.5%);
		// box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.2);
		-webkit-box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.3);
		box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.3);
		${EditButton} {
			opacity: 1;
		}
	}
`;

export const ColumnAvatar = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 50px;
	padding: 1rem 0 1rem 1rem;
`;

export const LinkCard = styled.a`
	padding: 1rem 1rem 1rem 0;
	text-decoration: none;
	flex-grow: 1;
`;

export const Badge = styled.span`
	color: var(--badge);
	background-color: var(--badge-background);
	border-left: 8px solid var(--badge-background);
	border-right: 8px solid var(--badge-background);
	border-bottom: 1px solid var(--badge-background);
	border-radius: 50px;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 1rem;
	display: inline-block;
`;

export const ListBadge = styled.div`
	font-size: 0.75rem;
`;

export const LoadingSpinner = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	margin-left: -50px;
	margin-top: -50px;
`;

export const ShowHideLink = styled.a`
	float: right;
	margin-left: 1rem;
`;

export const LogOutLink = ShowHideLink;

export const FlexHalfContainer = styled.div`
	display: block;
	@media (min-width: 767px) {
		display: flex;
	}
`;

export const FlexHalfLeft = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;

	@media (min-width: 767px) {
		padding-right: 1rem;
	}
`;

export const FlexHalfRight = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;

	@media (min-width: 767px) {
		padding-right: 1rem;
	}
`;
