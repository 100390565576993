import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconShow = ({ className }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' r='12' fill='#FFF' />
			<path
				stroke='#85144B'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.12'
				d='M11.8281,7 C9.10019999,7 6.30195,8.57885 4.09905,11.73655 C3.97046469,11.9228952 3.96673545,12.1683342 4.0896,12.3585 C5.7822,15.008 8.54299999,17.08 11.8281,17.08 C15.0775,17.08 17.895,15.0017 19.59075,12.34625 C19.7105896,12.1600491 19.7105896,11.9210009 19.59075,11.7348 C17.89115,9.1098 15.053,7 11.8281,7 Z'
			/>
			<circle cx='11.84' cy='12.04' r='2.8' stroke='#85144B' strokeWidth='1.12' />
		</g>
	</Svg>
);

export default IconShow;
