import React from 'react';
import IconBusiness from '../components/icons/IconBusiness';
import IconDevice from '../components/icons/IconDevice';
import IconNetwork from '../components/icons/IconNetwork';
import IconProfileFemale from '../components/icons/IconProfileFemale';
import IconProfileMale from '../components/icons/IconProfileMale';
import IconProgramming from '../components/icons/IconProgramming';
import IconTechology from '../components/icons/IconTechology';
import IconTypo3 from '../components/icons/IconTypo3';

export const DefaultColorOption = '#AAA';

export const ColorOptions = [
	{ value: '1', label: 'Pansy Purple', color: '#333', backgroundColor: '#7A214A' },
	{ value: '2', label: 'Ronchi', color: '#333', backgroundColor: '#E7B251' },
	{ value: '3', label: 'Perano', color: '#333', backgroundColor: '#9EB6ED' },
	{ value: '4', label: 'Chalet Green', color: '#333', backgroundColor: '#587436' }
];

export const IconOptions = [
	{
		value: '1',
		label: (
			<div className='optionIconInnerWrap'>
				<IconBusiness /> <span>Business</span>
			</div>
		)
	},
	{
		value: '2',
		label: (
			<div className='optionIconInnerWrap'>
				<IconDevice /> <span>Device</span>
			</div>
		)
	},
	{
		value: '3',
		label: (
			<div className='optionIconInnerWrap'>
				<IconNetwork /> <span>Network</span>
			</div>
		)
	},
	{
		value: '4',
		label: (
			<div className='optionIconInnerWrap'>
				<IconProgramming /> <span>Programming</span>
			</div>
		)
	},
	{
		value: '5',
		label: (
			<div className='optionIconInnerWrap'>
				<IconTechology /> <span>Techology</span>
			</div>
		)
	},
	{
		value: '6',
		label: (
			<div className='optionIconInnerWrap'>
				<IconProfileFemale /> <span>Female</span>
			</div>
		)
	},
	{
		value: '7',
		label: (
			<div className='optionIconInnerWrap'>
				<IconProfileMale /> <span>Male</span>
			</div>
		)
	},
	{
		value: '8',
		label: (
			<div className='optionIconInnerWrap'>
				<IconTypo3 /> <span>TYPO3</span>
			</div>
		)
	}
];
