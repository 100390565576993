import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';

//
// Styles
// --------------------------------------------------
import {
	HeaderNavigation,
	HeaderNavigationItem,
	HeaderNavigationLogo,
	HeaderNavigationSearch,
	HeaderNavigationMenu
} from './HeaderStyles';

import {
	Grid,
	GridForm,
	GridItem,
	GridItemForm,
	Card,
	CardForm,
	ColumnAvatar,
	LinkCard,
	ListBadge,
	Badge,
	EditButton,
	LoadingSpinner,
	ShowHideLink,
	LogOutLink,
	FlexHalfContainer,
	FlexHalfLeft,
	FlexHalfRight
} from './ListStyles';

//
// Service and Hooks
// --------------------------------------------------
import EmailDataService from '../services/EmailService';
import AuthenticationService from '../services/AuthenticationService';
import { DefaultColorOption, ColorOptions, IconOptions } from '../services/Theme';

//
// Components
// --------------------------------------------------
import Avatar from '../components/Avatar';
import HideButton from '../components/HideButton';
import IconEdit from '../components/icons/IconEdit';
import IconLogo from '../components/icons/IconLogo';
import IconShow from '../components/icons/IconShow';
import IconHide from '../components/icons/IconHide';
import IconLogout from '../components/icons/IconLogout';
import ReactTags from 'react-tag-autocomplete';
import TextareaAutosize from 'react-textarea-autosize';
import SelectOptions from '../components/SelectOptions';
import ImprintPrivacyLinkbar from '../components/ImprintPrivacyLinkbar';
import IconProfile from '../components/icons/IconProfile';

//
// List
// --------------------------------------------------
const List = () => {
	const [emails, setEmails] = useState([]);
	const [showHidden, setHiddenFlag] = useState(0);
	const [searchSubject, setSearchSubject] = useState('');

	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(false);
	const [editing, setEditing] = useState(false);

	const initialValues = {
		id: null,
		hidden: '0',
		date: '',
		link: '',
		sender: '',
		subject: '',
		note: ''
	};
	const [currentEmail, setCurrentEmail] = useState(initialValues);
	const [suggestTags, setSuggestTags] = useState([]);
	const [currentTags, setCurrentTags] = useState([]);
	const currentTag = useRef(null);
	const [allSuggestTags, setAllSuggestTags] = useState([]);

	useEffect(() => {
		setLoading(true);

		EmailDataService.tags()
			.then((response) => {
				setAllSuggestTags(response.data.tags);
			})
			.catch((e) => {
				console.log(e);
			});

		//
		// Check if we use the Search or the initial Api Call
		// Only do if searchSubject has min 2 characters
		// --------------------------------------------------
		if (searchSubject !== '' && searchSubject.length >= 2) {
			EmailDataService.findBySubject(searchSubject)
				.then((response) => {
					if (response.data.status === 'success') {
						setEmails(response.data.emails);
					} else {
						setEmails([]);
					}
					setLoading(false);
				})
				.catch((e) => {
					console.log(e);
					setLoading(false);
				});
		} else {
			// Only if we have an empty searchbobject do the init api call
			if (searchSubject === '') {
				EmailDataService.getAll(showHidden)
					.then((response) => {
						if (response.data.status === 'success') {
							//
							// Debug
							// --------------------------------------------------
							// console.log(response.data.emails);

							setEmails(response.data.emails);
						} else {
							setEmails([]);
						}
						setLoading(false);
					})
					.catch((e) => {
						console.log(e);
						setLoading(false);
					});
			}
		}
	}, [showHidden, searchSubject, saving]);

	const onChangeSearchSubject = (e) => {
		const searchSubject = e.target.value;
		setSearchSubject(searchSubject);
	};

	const parseDate = (d) => {
		const t = d.split(/[- :]/);
		return t[2] + '.' + t[1] + '.' + t[0];
	};

	const logOut = () => {
		AuthenticationService.logout();
	};

	const editEmail = (email) => {
		setEditing(true);
		setCurrentEmail({
			id: email.id,
			hidden: email.hidden,
			date: email.date,
			link: email.link,
			icon: email.icon,
			color: email.color,
			sender: email.sender,
			subject: email.subject,
			note: email.note,
			tags: email.tags
		});

		// set selected tags
		setCurrentTags(email.tags);

		// copy all tags from the user
		// and then filter out all selected
		// tags
		let suggestTags = allSuggestTags;
		if (email.tags) {
			email.tags.forEach((element) => {
				suggestTags = suggestTags.filter(function (obj) {
					return obj.name !== element.name;
				});
			});
		}
		setSuggestTags(suggestTags);
	};

	const onEditHandleChange = (event) => {
		const { target } = event;
		const { name, value } = target;
		setCurrentEmail({ ...currentEmail, [name]: value });
	};

	const onEditSubmit = (event) => {
		event.preventDefault();
		// console.log(currentEmail);
		// console.log(currentTags);

		setSaving(true);

		const data = {
			subject: currentEmail.subject,
			link: currentEmail.link,
			color: currentEmail.color,
			icon: currentEmail.icon,
			note: currentEmail.note,
			tags: currentTags || []
		};

		EmailDataService.update(currentEmail.id, data)
			.then((response) => {
				setEditing(false);
				setSaving(false);
			})
			.catch((e) => {
				setSaving(false);
				console.log(e);
			});
	};

	const onEditTagDelete = (i) => {
		const newTags = currentTags.slice(0);
		newTags.splice(i, 1);
		setCurrentTags(newTags);
	};

	const onEditTagAdd = (tag) => {
		if (currentTags) {
			const newTags = [...currentTags, tag];
			setCurrentTags(newTags);
		} else {
			setCurrentTags([tag]);
		}
	};

	const triggerSelect = (e) => {
		setCurrentEmail({ ...currentEmail, [e.name]: e.value });
	};

	const getCardBorderColor = (e) => {
		if (e > 0) {
			const value = ColorOptions.filter((item) => item.value === e).map((filteredItem) => filteredItem);
			return value[0].backgroundColor;
		} else {
			return DefaultColorOption;
		}
	};

	return (
		<>
			{!editing ? (
				// ***********
				// List Mode
				// ***********
				<>
					<header>
						<HeaderNavigation>
							<HeaderNavigationItem>
								<HeaderNavigationLogo>
									<Link to='/'>
										<IconLogo />
									</Link>
								</HeaderNavigationLogo>
								<HeaderNavigationSearch>
									<input
										type='text'
										className='form-control'
										placeholder='Search'
										value={searchSubject}
										onChange={onChangeSearchSubject}
									/>
								</HeaderNavigationSearch>
								<HeaderNavigationMenu>
									<LogOutLink href='/' onClick={logOut}>
										<IconLogout />
									</LogOutLink>
									<Link to='/profile'>
										<IconProfile />
									</Link>
									<ShowHideLink onClick={() => setHiddenFlag(showHidden ? 0 : 1)}>
										{showHidden ? <IconHide /> : <IconShow />}
									</ShowHideLink>
								</HeaderNavigationMenu>
							</HeaderNavigationItem>
						</HeaderNavigation>
					</header>

					<Grid>
						{emails &&
							emails.map((email, index) => (
								<GridItem key={index} className='GridItem'>
									<Card style={{ borderTop: '5px solid ' + getCardBorderColor(email.color) }}>
										<ColumnAvatar>
											<Avatar
												icon={email.icon}
												subject={email.subject}
												color={getCardBorderColor(email.color)}
											/>
											<EditButton onClick={() => editEmail(email)}>
												<IconEdit />
											</EditButton>
										</ColumnAvatar>

										<LinkCard href={email.link} target='_blank' rel='noopener noreferrer'>
											<ListBadge>
												{email.tags &&
													email.tags.map((tag, index) => (
														<Badge key={index}>{tag.name}</Badge>
													))}
											</ListBadge>
											<div className='content-text'>{email.subject}</div>
										</LinkCard>
									</Card>
								</GridItem>
							))}

						{emails.length === 0 && (
							<Fragment>
								{loading && <LoadingSpinner className='loader'>Loading ...</LoadingSpinner>}
								{!loading && <div className='column'>Ohhh, wir haben leider nichts gefunden.</div>}
							</Fragment>
						)}
					</Grid>
					<Grid>{!loading && <ImprintPrivacyLinkbar />}</Grid>
				</>
			) : (
				// ***********
				// Edit Mode
				// ***********
				<>
					<header>
						<HeaderNavigation>
							<HeaderNavigationItem>
								<HeaderNavigationLogo>
									<Link to='/'>
										<IconLogo />
									</Link>
								</HeaderNavigationLogo>
							</HeaderNavigationItem>
						</HeaderNavigation>
					</header>

					<GridForm>
						<GridItemForm>
							<CardForm>
								<form onSubmit={onEditSubmit}>
									<FlexHalfContainer>
										<FlexHalfLeft>
											<h1 className='m0'>Eintrag bearbeiten</h1>
										</FlexHalfLeft>
										<FlexHalfRight className='text-align-right'>
											<HideButton email={currentEmail} setHiddenFlag={setHiddenFlag} />
										</FlexHalfRight>
									</FlexHalfContainer>

									<div className='form-group'>
										<label>Betreff</label>
										<input
											type='text'
											name='subject'
											onChange={onEditHandleChange}
											value={currentEmail.subject}
										/>
									</div>

									<div className='form-group'>
										<label>Notiz</label>
										<TextareaAutosize
											type='text'
											name='note'
											onChange={onEditHandleChange}
											value={currentEmail.note}
										/>
									</div>

									<FlexHalfContainer>
										<FlexHalfLeft>
											<div className='form-group'>
												<label>Farbe</label>
												<SelectOptions
													name='color'
													options={ColorOptions}
													useColorDot
													triggerSelect={triggerSelect}
													selected={currentEmail.color}
												/>
											</div>
										</FlexHalfLeft>
										<FlexHalfRight>
											<div className='form-group'>
												<label>Icon</label>
												<SelectOptions
													name='icon'
													options={IconOptions}
													triggerSelect={triggerSelect}
													selected={currentEmail.icon}
												/>
											</div>
										</FlexHalfRight>
									</FlexHalfContainer>

									<div className='form-group'>
										<label>Link</label>
										<input
											type='text'
											name='link'
											onChange={onEditHandleChange}
											value={currentEmail.link}
										/>
									</div>

									<div className='form-group'>
										<label>Tags</label>
										<ReactTags
											ref={currentTag}
											tags={currentTags}
											suggestions={suggestTags}
											onDelete={onEditTagDelete}
											onAddition={onEditTagAdd}
											allowNew
											placeholderText='Tag hinzufügen'
										/>
									</div>

									<div className='form-group'>
										<button className='btn position-relative form-control'>
											{saving && (
												<>
													<span className='btnSpinnerWrapper'>
														<span className='btnSpinner loader'>Speichern</span>
													</span>
													<span className='white loading'>Speichern</span>
												</>
											)}
											{!saving && <span className='white'>Speichern</span>}
										</button>
									</div>
								</form>

								<div className='form-group mt mb2'>
									<button
										className='btn btn50 btnBgDark form-control'
										onClick={() => setEditing(false)}
									>
										<span className='white'>Abbrechen</span>
									</button>
								</div>

								<ListBadge>
									<Badge>{parseDate(currentEmail.date)}</Badge>
								</ListBadge>
							</CardForm>
						</GridItemForm>
					</GridForm>
					<Grid>
						<ImprintPrivacyLinkbar />
					</Grid>
				</>
			)}
		</>
	);
};

export default List;
