import React from 'react';
import IconBusiness from './icons/IconBusiness';
import IconDevice from './icons/IconDevice';
import IconNetwork from './icons/IconNetwork';
import IconProfileFemale from './icons/IconProfileFemale';
import IconProfileMale from './icons/IconProfileMale';
import IconProgramming from './icons/IconProgramming';
import IconTechology from './icons/IconTechology';
import IconTypo3 from './icons/IconTypo3';

const Avatar = (props) => {
	const { icon, subject, color } = props;

	const firstLetter = (d) => {
		return d.charAt(0);
	};

	const contrastColor = (hex) => {
		if (hex.indexOf('#') === 0) {
			hex = hex.slice(1);
		}
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		const r = parseInt(hex.slice(0, 2), 16);
		const g = parseInt(hex.slice(2, 4), 16);
		const b = parseInt(hex.slice(4, 6), 16);
		return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
	};

	let char;
	switch (icon) {
		case '1':
			char = '1';
			return <IconBusiness fill={color} color={contrastColor(color)} />;
		case '2':
			return <IconDevice fill={color} color={contrastColor(color)} />;
		case '3':
			return <IconNetwork fill={color} color={contrastColor(color)} />;
		case '4':
			return <IconProgramming fill={color} color={contrastColor(color)} />;
		case '5':
			return <IconTechology fill={color} color={contrastColor(color)} />;
		case '6':
			return <IconProfileFemale fill={color} color={contrastColor(color)} />;
		case '7':
			return <IconProfileMale fill={color} color={contrastColor(color)} />;
		case '8':
			return <IconTypo3 fill={color} color={contrastColor(color)} />;
		default:
			char = firstLetter(subject);
			return (
				<svg width='30' height='30'>
					<circle cx='15' cy='15' r='15' fill={color} />
					<text x='50%' y='50%' textAnchor='middle' fill={contrastColor(color)} fontSize='1rem' dy='.3125em'>
						{char}
					</text>
				</svg>
			);
	}
};

export default Avatar;
