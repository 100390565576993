import React from 'react';
import { Link } from 'react-router-dom';

import { HeaderNavigation, HeaderNavigationItem, HeaderNavigationLogo } from './HeaderStyles';
import { DefaultPageGrid, DefaultPageGridItem } from './DefaultPageStyles';

import IconLogo from '../components/icons/IconLogo';
import ImprintPrivacyLinkbar from '../components/ImprintPrivacyLinkbar';

const FourZeroFour = (props) => {
	return (
		<>
			<header>
				<HeaderNavigation>
					<HeaderNavigationItem>
						<HeaderNavigationLogo>
							<Link to='/'>
								<IconLogo />
							</Link>
						</HeaderNavigationLogo>
					</HeaderNavigationItem>
				</HeaderNavigation>
			</header>

			<DefaultPageGrid>
				<DefaultPageGridItem>
					<h1>404</h1>
					<p>Oha! Da wurde wohl nichts gefunden, wie kann das sein?</p>
					<ImprintPrivacyLinkbar />
				</DefaultPageGridItem>
			</DefaultPageGrid>
		</>
	);
};

export default FourZeroFour;
