import Api from './Api';
import AuthorizationHeader from './AuthorizationHeader';

const getAll = (showHidden) => {
	if (showHidden) {
		return Api.get('/hidden', { headers: AuthorizationHeader() });
	} else {
		return Api.get('/get', { headers: AuthorizationHeader() });
	}
};

const tags = () => {
	return Api.get(`/tags`, { headers: AuthorizationHeader() });
};

const get = (id) => {
	return Api.get(`/get/${id}`, { headers: AuthorizationHeader() });
};

const update = (id, data) => {
	return Api.put(`/update/${id}`, data, { headers: AuthorizationHeader() });
};

const remove = (id) => {
	return Api.delete(`/delete/${id}`, { headers: AuthorizationHeader() });
};

const findBySubject = (title) => {
	return Api.get(`/search/${title}`, { headers: AuthorizationHeader() });
};

export default {
	getAll,
	tags,
	get,
	update,
	remove,
	findBySubject
};
