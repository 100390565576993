import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconProfileMale = ({ className, fill, color }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' fill={fill || '#FFF'} r='12' />
			<path
				d='m19 19-4.4545455-1.826087v-2.4347826s1.1021819-.4260869 1.1021819-3.0434782c.8705454 0 1.2014545-2.43478263-.0089091-2.43478263.5228998-.70589442.7248989-1.58415142.56-2.43478261-.6249091-2.43478261-6.78490912-2.43478261-7.40981821 0-2.62945454-.52347826-.53709091 2.08417391-.53709091 2.43478261-1.24854545 0-1.24854545 2.43478263 0 2.43478263 0 2.6173913 1.20272727 3.0434782 1.20272727 3.0434782v2.4347826l-4.45454545 1.826087'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.198'
			/>
		</g>
	</Svg>
);

export default IconProfileMale;
