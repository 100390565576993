import React, { useState, useRef } from 'react';
import Select, { createFilter } from 'react-select';

const SelectOptions = (props) => {
	const { selected, name, options, triggerSelect, useColorDot } = props;
	const [selectedOption, setSelectedOption] = useState(selected);
	const textInput = useRef(null);

	let dot = (color = '#ccc') => ({});
	if (useColorDot) {
		dot = (color = '#ccc') => ({
			alignItems: 'center',
			display: 'flex',

			':before': {
				backgroundColor: color,
				borderRadius: 10,
				content: '" "',
				display: 'block',
				marginRight: 8,
				height: 10,
				width: 10
			}
		});
	}

	const filterConfig = {
		ignoreCase: true,
		ignoreAccents: true,
		matchFromStart: 'any'
	};

	const customStyles = {
		menu: (provided, state) => {
			return { ...provided, cursor: 'pointer' };
		},

		option: (provided, state) => {
			return {
				...provided,
				...dot(state.data.backgroundColor),
				color: state.data.color,
				cursor: 'pointer',
				borderTop: '1px solid #FFF',
				borderBottom: '1px solid #FFF',
				background: state.isSelected ? '#EEE' : '#FFF',
				'&:hover': {
					background: '#EEE'
				}
			};
		},

		singleValue: (provided, state) => {
			return { ...provided, ...dot(state.data.backgroundColor), color: state.data.color };
		}
	};

	const handleChange = (e) => {
		if (e) {
			setSelectedOption(e.value);
			triggerSelect({ name, ...e });
		} else {
			setSelectedOption(0);
			const initValue = {
				value: '0',
				label: ''
			};
			triggerSelect({ name, ...initValue });
		}
	};

	return (
		<Select
			ref={textInput}
			name={name}
			styles={customStyles}
			defaultValue={options.filter((item) => item.value === selectedOption).map((filteredItem) => filteredItem)}
			onChange={handleChange}
			options={options}
			filterOption={createFilter(filterConfig)}
			isClearable
			isSearchable
			placeholder='Bitte wähle'
		/>
	);
};

export default SelectOptions;
