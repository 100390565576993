import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconTypo3 = ({ className, fill, color }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' fill={fill || '#FFF'} r='12' />
			<path
				d='m10.9904002 5c-2.24640005.28800001-4.83840015 1.09440004-5.70240019 2.18880009-.17280001.23040001-.28800001.63360002-.28800001 1.20960005 0 3.34080016 3.45600014 11.00160046 5.9904002 11.00160046 1.1520001 0 3.1680002-1.9584001 4.7808002-4.5504002-.2304.0576-.4032.0576-.6336.0576-1.8432001 0-4.5504002-6.50880026-4.5504002-8.64000035 0-.80640003.1728-1.03680004.4032-1.26720005zm3.3984002 0c-.9792001 0-1.3248001.17280001-1.3248001 1.15200005 0 2.13120008 1.7280001 6.33600025 3.2256001 6.33600025.8640001 0 2.5344001-3.16800013 2.5344001-5.81760023 0-1.32480006-2.1312-1.67040007-4.4352001-1.67040007z'
				stroke={color || '#85144B'}
				strokeWidth='1.2'
			/>
		</g>
	</Svg>
);

export default IconTypo3;
