import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { HeaderNavigation, HeaderNavigationItem, HeaderNavigationLogo } from './HeaderStyles';
import { DefaultPageGrid, DefaultPageGridItem } from './DefaultPageStyles';

import EmailDataService from '../services/EmailService';
import AuthenticationService from '../services/AuthenticationService';

import IconLogo from '../components/icons/IconLogo';
import ImprintPrivacyLinkbar from '../components/ImprintPrivacyLinkbar';

const Profile = () => {
	const [loadingTags, setLoadingTags] = useState(true);
	const [loadingPwdReset, setLoadingPwdReset] = useState(false);
	const [messagePwdReset, setMessagePwdReset] = useState('');
	const [allSuggestTags, setAllSuggestTags] = useState([]);

	// eslint-disable-next-line no-undef
	const user = JSON.parse(localStorage.getItem('user'));
	const email = user.email;

	useEffect(() => {
		setLoadingTags(true);
		EmailDataService.tags()
			.then((response) => {
				setAllSuggestTags(response.data.tags);
				setLoadingTags(false);
			})
			.catch((e) => {
				console.log(e);
				setLoadingTags(false);
			});
	}, []);

	const handlePwdReset = (e) => {
		e.preventDefault();

		setMessagePwdReset('');
		setLoadingPwdReset(true);

		if (email) {
			AuthenticationService.forgotpwd(email).then(
				(data) => {
					const resStatus = (data && data.status) || data.status.toString();
					const resMessage = (data && data.message) || data.message.toString();
					setLoadingPwdReset(false);
					if (resStatus === 'success') {
						setMessagePwdReset('√ ' + resMessage);
					} else {
						setMessagePwdReset(resMessage);
					}
				},
				(error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) ||
						error.message ||
						error.toString();

					setLoadingPwdReset(false);
					setMessagePwdReset(resMessage);
				}
			);
		} else {
			setLoadingPwdReset(false);
			setMessagePwdReset('Aus technischen Gründen konnte keine Email gefunden werden?¿');
		}
	};

	return (
		<>
			<header>
				<HeaderNavigation>
					<HeaderNavigationItem>
						<HeaderNavigationLogo>
							<Link to='/'>
								<IconLogo />
							</Link>
						</HeaderNavigationLogo>
					</HeaderNavigationItem>
				</HeaderNavigation>
			</header>

			<DefaultPageGrid>
				<DefaultPageGridItem>
					<h1>Profil</h1>
					<p>
						<strong>Hier kannst Du Dein Profil einstellen.</strong>
					</p>
					<hr />
					<p className='mt2 mb2'>
						Du bist mit dieser Email <strong>{email}</strong> bei Emailist eingeloggt.
					</p>
					<hr />
					<p className='mt2 mb2'>
						Aktuell nutzt Du diese Tags: {loadingTags && <>Loading</>}
						{!loadingTags &&
							allSuggestTags &&
							allSuggestTags.map((tag, index) => (
								<span key={index}>
									<span className='profile-tag'>{tag.name}</span>
								</span>
							))}
					</p>
					<hr />
					{!messagePwdReset && (
						<>
							<p className='mt2 mb2'>
								Du nmöchtest Dein Passwort zurücksetzen? Das geht ganz einfach. Bestätige Deinen Wunsch
								indem Du unten auf den Button 'Passwort zurücksetzen' klickst. Wir senden Dir dann einen
								Email mit weiteren Instruktionen. Aus Sicherheitsgründen kannst Du nur alle 10 Minuten
								Dein Passwort zurücksetzen.
							</p>
							<div className='mt2 mb2 text-align-right'>
								<button
									className='btn btnSmall position-relative'
									disabled={loadingPwdReset}
									onClick={handlePwdReset}
								>
									{loadingPwdReset && (
										<>
											<span className='btnSpinnerWrapper'>
												<span className='btnSpinner loader'>Loading</span>
											</span>
											<span className='white loading'>Loading</span>
										</>
									)}
									{!loadingPwdReset && <span className='white'>Passwort zurücksetzen</span>}
								</button>
							</div>
						</>
					)}
					{messagePwdReset && (
						<div className='alert alert-danger mt2 mb2' role='alert'>
							{messagePwdReset}
						</div>
					)}
					<hr />
					<p className='mt2 mb2'>
						<Link to='/list' className='btn white'>
							Zurück
						</Link>
					</p>
					<ImprintPrivacyLinkbar />
				</DefaultPageGridItem>
			</DefaultPageGrid>
		</>
	);
};

export default Profile;
