import React from 'react';
import { Link } from 'react-router-dom';

const ImprintPrivacyLinkbar = (props) => {
	const showForgotPwd = props.showForgotPwd;

	return (
		<>
			<p className='mt3'>
				<Link to='/privacy' className='link small mr'>
					Datenschutz
				</Link>
				<Link to='/imprint' className='link small mr'>
					Impressum
				</Link>
				{showForgotPwd && (
					<Link to='/forgotpwd' className='link small mr'>
						Passwort vergessen
					</Link>
				)}
			</p>
		</>
	);
};

export default ImprintPrivacyLinkbar;
