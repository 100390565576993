import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconBusiness = ({ className, fill, color }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' r='12' fill={fill || '#FFF'} />
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M20.0636361 16.9876207C20.0636361 17.3651399 19.7575963 17.6711797 19.3800771 17.6711797L5.02533848 17.6711797C4.64781927 17.6711797 4.34177949 17.3651399 4.34177949 16.9876207L4.34177949 7.41779492C4.34177949 7.04027572 4.64781927 6.73423594 5.02533848 6.73423594L19.3800771 6.73423594C19.7575963 6.73423594 20.0636361 7.04027572 20.0636361 7.41779492L20.0636361 16.9876207zM9.46847187 6.73423594L9.81025136 4.34177949C9.81025136 4.15301989 9.96327125 4 10.1520309 4L14.2533848 4C14.4421444 4 14.5951643 4.15301989 14.5951643 4.34177949L14.9369437 6.73423594'
			/>
			<line
				x1='7.076'
				x2='17.329'
				y1='9.47'
				y2='9.47'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='7.076'
				x2='17.329'
				y1='12.204'
				y2='12.204'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='7.076'
				x2='17.329'
				y1='14.938'
				y2='14.938'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</g>
	</Svg>
);

export default IconBusiness;
