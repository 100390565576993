import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconDevice = ({ className, fill, color }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' r='12' fill={fill || '#FFF'} />
			<rect
				width='11.22'
				height='7.92'
				x='6.31'
				y='4'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				rx='1.32'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M19.4618194,17.3933795 C19.5530491,17.6520973 19.5130796,17.9389963 19.3546096,18.1629271 C19.1961395,18.3868579 18.9388709,18.5199994 18.6645394,18.5199994 L5.17545996,18.5199994 C4.90112846,18.5199994 4.6438599,18.3868579 4.48538983,18.1629271 C4.32691975,17.9389963 4.28695031,17.6520973 4.37817999,17.3933795 L5.87043993,13.1647597 C6.13376536,12.4187757 6.83890423,11.9199997 7.62999986,11.9199997 L16.2099995,11.9199997 C17.0009789,11.9198252 17.7059863,12.4187536 17.9688995,13.1647597 L19.4618194,17.3933795 Z'
			/>
			<polygon
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				points='14.23 18.52 9.61 18.52 10.27 16.54 13.57 16.54'
			/>
			<line
				x1='7.627'
				x2='8.287'
				y1='13.9'
				y2='13.9'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='9.607'
				x2='10.267'
				y1='13.9'
				y2='13.9'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='13.567'
				x2='14.227'
				y1='13.9'
				y2='13.9'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='15.547'
				x2='16.207'
				y1='13.9'
				y2='13.9'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='11.587'
				x2='12.247'
				y1='13.9'
				y2='13.9'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='6.967'
				x2='7.627'
				y1='15.22'
				y2='15.22'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='8.947'
				x2='14.887'
				y1='15.22'
				y2='15.22'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='16.207'
				x2='16.867'
				y1='15.22'
				y2='15.22'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</g>
	</Svg>
);

export default IconDevice;
