import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import AuthenticationService from '../services/AuthenticationService';
import AuthorizationHeader from '../services/AuthorizationHeader';

import { HeaderNavigation, HeaderNavigationItem, HeaderNavigationLogo } from './HeaderStyles';
import { LoginGrid, LoginGridItem } from './LoginStyles';
import IconLogo from '../components/icons/IconLogo';
import ImprintPrivacyLinkbar from '../components/ImprintPrivacyLinkbar';

const Login = (props) => {
	const form = useRef();

	const isAuth = AuthorizationHeader();
	const location = useLocation();

	if (location.pathname === '/' && isAuth.Authorization) {
		props.history.push('/list');
		window.location.reload();
	}

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');

	const onChangeEmail = (e) => {
		const email = e.target.value;
		setEmail(email);
	};

	const onChangePassword = (e) => {
		const password = e.target.value;
		setPassword(password);
	};

	const handleLogin = (e) => {
		e.preventDefault();

		setMessage('');
		setLoading(true);

		if (email && password) {
			AuthenticationService.login(email, password).then(
				() => {
					props.history.push('/list');
					window.location.reload();
				},
				(error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) ||
						error.message ||
						error.toString();

					setLoading(false);
					setMessage(resMessage);
				}
			);
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			<header>
				<HeaderNavigation>
					<HeaderNavigationItem>
						<HeaderNavigationLogo>
							<IconLogo />
						</HeaderNavigationLogo>
					</HeaderNavigationItem>
				</HeaderNavigation>
			</header>

			<LoginGrid>
				<LoginGridItem>
					<h1>Login</h1>
					<form onSubmit={handleLogin} ref={form}>
						<div className='form-group'>
							<label htmlFor='email'>Email</label>
							<input
								type='email'
								className='form-control'
								name='email'
								value={email}
								required='required'
								onChange={onChangeEmail}
							/>
						</div>

						<div className='form-group'>
							<label htmlFor='password'>Passwort</label>
							<input
								type='password'
								className='form-control'
								name='password'
								value={password}
								required='required'
								onChange={onChangePassword}
							/>
						</div>

						<div className='form-group'>
							<button className='btn position-relative' disabled={loading}>
								{loading && (
									<>
										<span className='btnSpinnerWrapper'>
											<span className='btnSpinner loader'>Loading</span>
										</span>
										<span className='white loading'>Loading</span>
									</>
								)}
								{!loading && <span className='white'>Login</span>}
							</button>
						</div>

						{message && (
							<div className='form-group'>
								<div className='alert alert-danger' role='alert'>
									{message}
								</div>
							</div>
						)}
					</form>

					<ImprintPrivacyLinkbar showForgotPwd />
				</LoginGridItem>
			</LoginGrid>
		</>
	);
};

export default Login;
