import Api from './Api';

const login = (email, password) => {
	return Api.post('/login', {
		email,
		password
	}).then((response) => {
		if (response.data) {
			// eslint-disable-next-line no-undef
			localStorage.setItem('user', JSON.stringify(response.data));
		}
		return response.data;
	});
};

const logout = () => {
	// eslint-disable-next-line no-undef
	localStorage.removeItem('user');
};

const forgotpwd = (email) => {
	return Api.post('/forgotpwd', {
		email
	}).then((response) => {
		return response.data;
	});
};

const newpwd = (token, password) => {
	return Api.post('/newpwd', {
		token,
		password
	}).then((response) => {
		return response.data;
	});
};

export default {
	login,
	logout,
	forgotpwd,
	newpwd
};
