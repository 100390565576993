import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconProgramming = ({ className, fill, color }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' r='12' fill={fill || '#FFF'} />
			<polyline
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				points='14.568 12.237 16.647 14.316 14.568 16.395'
			/>
			<polyline
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				points='9.371 12.237 7.292 14.316 9.371 16.395'
			/>
			<line
				x1='13.009'
				x2='10.93'
				y1='12.237'
				y2='16.395'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<rect
				width='15.939'
				height='12.474'
				x='4'
				y='6'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				rx='1.386'
			/>
			<line
				x1='4'
				x2='19.939'
				y1='9.465'
				y2='9.465'
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				stroke={color || '#85144B'}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M6.42549998 7.55924999C6.52118331 7.55924999 6.59874998 7.63681665 6.59874998 7.73249999 6.59874998 7.82818332 6.52118331 7.90574999 6.42549998 7.90574999 6.32981665 7.90574999 6.25224998 7.82818332 6.25224998 7.73249999 6.25224998 7.63681665 6.32981665 7.55924999 6.42549998 7.55924999M8.50449997 7.55924999C8.6001833 7.55924999 8.67774997 7.63681665 8.67774997 7.73249999 8.67774997 7.82818332 8.6001833 7.90574999 8.50449997 7.90574999 8.40881663 7.90574999 8.33124997 7.82818332 8.33124997 7.73249999 8.33124997 7.63681665 8.40881663 7.55924999 8.50449997 7.55924999M10.5835 7.55924999C10.6791833 7.55924999 10.75675 7.63681665 10.75675 7.73249999 10.75675 7.82818332 10.6791833 7.90574999 10.5835 7.90574999 10.4878166 7.90574999 10.41025 7.82818332 10.41025 7.73249999 10.41025 7.63681665 10.4878166 7.55924999 10.5835 7.55924999'
			/>
		</g>
	</Svg>
);

export default IconProgramming;
