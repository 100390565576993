import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)`
	width: 30px;
	height: auto;
	cursor: pointer;
`;

export const IconLogout = ({ className }) => (
	<Svg viewBox='0 0 24 24' className={className}>
		<g fill='none'>
			<circle cx='12' cy='12' r='12' fill='#FFF' />
			<path
				stroke='#85144B'
				strokeLinecap='round'
				strokeWidth='1.25'
				d='M12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C8.6862915,6 6,8.6862915 6,12'
				transform='rotate(135 12 12)'
			/>
			<line x1='12' x2='12' y1='5' y2='11.375' stroke='#85144B' strokeLinecap='round' strokeWidth='1.25' />
		</g>
	</Svg>
);

export default IconLogout;
