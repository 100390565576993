import styled from 'styled-components';

export const HeaderNavigation = styled.div`
	width: 100%;
	background-color: #fff;
	padding: 0;
	margin: 0;
`;

export const HeaderNavigationItem = styled.div`
	padding: 3rem;
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	align-items: flex-start;

	@media (min-width: 767px) {
		flex-flow: row nowrap;
		align-items: center;
	}
`;

export const HeaderNavigationLogo = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 120px;
	max-height: 4rem;
`;

export const HeaderNavigationSearch = styled.div`
	width: 100%;
	max-height: 4rem;
	background-color: #fff;
	padding: 0;
	margin-top: 20px;

	order: 3;
	@media (min-width: 767px) {
		margin: 0;
		order: 2;
	}
`;

export const HeaderNavigationMenu = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 120px;
	max-height: 4rem;
	text-align: right;
	margin-top: -40px;

	order: 2;
	align-self: flex-end;

	@media (min-width: 767px) {
		margin: 0;
		order: 3;
	}
`;
