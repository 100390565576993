import styled from 'styled-components';

export const LoginGrid = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;

	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
`;

export const LoginGridItem = styled.div`
	width: 240px;
	@media (min-width: 321px) {
		width: 320px;
	}
	@media (min-width: 481px) {
		width: 480px;
	}
	padding: 3rem;
	background-color: #fff;
	border-radius: 0.5rem;
	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
`;
