import styled from 'styled-components';

export const DefaultPageGrid = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
`;

export const DefaultPageGridItem = styled.div`
	width: 240px;

	@media (min-width: 321px) {
		width: 320px;
	}
	@media (min-width: 481px) {
		width: 80%;
	}
	padding: 3rem;
	margin-top: 2rem;
	background-color: #fff;
	border-radius: 0.5rem;
	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
`;
